import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import SuccessIcon from '../images/checkmark-outline.svg';
import PrimaryButton from '../components/primary-button';

const ThanksBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
`;

const Success = styled.img`
  width: 6rem;
`;

const Message = styled.h1`
  text-align: center;
  line-height: 1.5;
  margin-top: 0.25em;

  span {
    display: block;
    margin-bottom: 0.5em;
  }
`;

const ThankYouPage = ({ location }) => {
  const { state } = location;
  const { message } = state || {};
  return (
    <Layout>
      <SEO title="Köszönjük" />
      <ThanksBody>
        <Success src={SuccessIcon} />
        <Message>
          {message}
          <span>Köszönjük!</span>
        </Message>
        <PrimaryButton as={Link} to="/" outline="true">
          Vissza a főoldalra
        </PrimaryButton>
      </ThanksBody>
    </Layout>
  );
};

export default ThankYouPage;
